import { useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Input, Stack, Radio, Button, Dialog, Checkbox, Typography, RadioGroup, FormControlLabel } from '@mui/material';

import useDomainInfo from 'src/hooks/use-domain-info';

import { formsApi } from 'src/api/forms';

import { useSnackbar } from 'src/components/snackbar';
import { LeftIcon, RightIcon } from 'src/components/carousel/arrow-icons';


export const FormModal = ({ formId, settings, open, onClose, currentFormIndex, formListCount, handleNextForm, handlePrevForm, handleSubmittedForm }) => {
  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [brand_id, setBrandId] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const { domainData } = useDomainInfo();

  useEffect (() => initialize, [domainData])

  const initialize = async () => {
    setBrandId(sessionStorage.getItem("brandId"));
  }

   // Handle input change
   const handleInputChange = (id, value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [id]: value,
    }));
  };

  // Handle checkbox change for multi-select
  const handleCheckboxChange = (id, optionId) => {
    setFormValues(prevValues => {
      const currentSelections = prevValues[id] || [];
      const updatedSelections = currentSelections.includes(optionId)
        ? currentSelections.filter(opt => opt !== optionId)
        : [...currentSelections, optionId];
      return {
        ...prevValues,
        [id]: updatedSelections,
      };
    });
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const result = settings.map(setting => ({
      ...setting,
      value: formValues[setting.id] || null
    }));
    const formData = new FormData();
    formData.append('internal_brand_id', brand_id);
    formData.append('brand_form_id', formId);
    formData.append('settings', JSON.stringify(result));
    formsApi.submitForm(formData).then(() => {
      handleSubmittedForm(formId);
      enqueueSnackbar('Form is submitted successfully.', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Stack sx={{ p: 4, minHeight: 600, justifyContent: 'space-between' }} spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Stack alignItems="flex-end">
            <Typography variant="h4">Fill the forms and Submit</Typography>
          </Stack>
        </Stack>
        <Stack direction="column" fontSize={20} mx={2} sx={{ height: '100%', flexGrow: 1 }}>
          {
            settings?.map(setting => {
              if (setting.inputType === 1) { // Input
                return (
                  <>
                    <Typography marginTop={3}>{setting.name}</Typography>
                    <Input 
                      sx={{ maxWidth: '100%' }} 
                      onChange={e => handleInputChange(setting.id, e.target.value)}
                    />
                  </>
                );
              } 
              if ( setting.inputType === 2 && setting.options?.length > 0 ) { // Single Select
                return (
                  <>
                    <Typography marginTop={3}>{setting.name}</Typography>
                    <RadioGroup 
                      name={setting.id}
                      onChange={e => handleInputChange(setting.id, e.target.value)} // Update state
                    >
                      { 
                        setting.options.map(option => (
                          <FormControlLabel 
                            key={option.id} 
                            control={
                              <Radio size="normal" value={option.id} sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} />
                            } 
                            label={option.option} 
                          />
                        )) 
                      }
                    </RadioGroup>
                  </>
                );
              }
              if ( setting.inputType === 3 && setting.options?.length > 0 ) { // Multi Select
                return (
                  <>
                    <Typography marginTop={3}>{setting.name}</Typography>
                    { 
                      setting.options.map(option => (
                        <FormControlLabel 
                          key={option.id} 
                          control={
                            <Checkbox 
                              size="normal" 
                              value={option.id} 
                              onChange={() => handleCheckboxChange(setting.id, option.id)}
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} 
                            />
                          } 
                          label={option.option} 
                        />
                      )) 
                    }
                  </>
                );
              }
              return null;
            })
          }
          <LoadingButton 
            loading={isSubmitting}
            color="primary" 
            variant="contained" 
            sx={{marginTop: 2, width: 150, marginLeft: 'auto'}}
            onClick={handleSubmit} 
          >
            Submit
          </LoadingButton>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Button onClick={handlePrevForm} disabled={currentFormIndex === 0}>
            <LeftIcon />
          </Button>
          {`${currentFormIndex + 1} / ${formListCount}`}
          <Button onClick={handleNextForm} disabled={currentFormIndex >= formListCount - 1}>
            <RightIcon />
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
