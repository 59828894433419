import merge from 'lodash/merge';
// date fns
import {
  fr as frFRAdapter,
  de as deDEAdapter,
  es as esESAdapter,
  ru as ruRUAdapter,
  da as daDAAdapter,
  enUS as enUSAdapter,
  arSA as arSAAdapter,
  itCH as itCHAdapter,
} from 'date-fns/locale';

// date pickers (MUI)
import {
  enUS as enUSDate,
  frFR as frFRDate,
  deDE as deDEDate,
  esES as esESDate,
  ruRU as ruRUDate,
  nlNL as nlNLDate,
  itIT as itCHDate,
} from '@mui/x-date-pickers/locales';
// core (MUI)
import {
  enUS as enUSCore,
  frFR as frFRCore,
  arSA as arSACore,
  deDE as deDECore,
  esES as esESCore,
  ruRU as ruRUCore,
  nlNL as nlNLCore,
  itIT as itCHCore,
} from '@mui/material/locale';
// data grid (MUI)
import {
  enUS as enUSDataGrid,
  frFR as frFRDataGrid,
  arSD as arSDDataGrid,
  deDE as deDEDataGrid,
  esES as esESDataGrid,
  ruRU as ruRUDataGrid,
  nlNL as nlNLDataGrid,
  itIT as itCHDataGrid,
} from '@mui/x-data-grid';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
    numberFormat: {
      code: 'en-US',
      currency: 'USD',
    },
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    adapterLocale: frFRAdapter,
    icon: 'flagpack:fr',
    numberFormat: {
      code: 'fr-Fr',
      currency: 'EUR',
    },
  },
  {
    label: 'German',
    value: 'de',
    systemValue: merge(deDEDate, deDEDataGrid, deDECore),
    adapterLocale: deDEAdapter,
    icon: 'flagpack:de',
    numberFormat: {
      code: 'de-De',
      currency: 'EUR',
    },
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: merge(esESDate, esESDataGrid, esESCore),
    adapterLocale: esESAdapter,
    icon: 'flagpack:es',
    numberFormat: {
      code: 'es-ES',
      currency: 'EUR',
    },
  },
  {
    label: 'Italian',
    value: 'it',
    systemValue: merge(itCHDate, itCHDataGrid, itCHCore),
    adapterLocale: itCHAdapter,
    icon: 'flagpack:it',
    numberFormat: {
      code: 'it-IT',
      currency: 'EUR',
    },
  },
  {
    label: 'Russian',
    value: 'ru',
    systemValue: merge(ruRUDate, ruRUDataGrid, ruRUCore),
    adapterLocale: ruRUAdapter,
    icon: 'flagpack:ru',
    numberFormat: {
      code: 'ru-RU',
      currency: 'RUB',
    },
  },
  {
    label: 'Arabic',
    value: 'ar',
    systemValue: merge(arSDDataGrid, arSACore),
    adapterLocale: arSAAdapter,
    icon: 'flagpack:sa',
    numberFormat: {
      code: 'ar',
      currency: 'AED',
    },
  },
  {
    label: 'Dutch',
    value: 'nl',
    systemValue: merge(nlNLDate, nlNLDataGrid, nlNLCore),
    adapterLocale: daDAAdapter,
    icon: 'flagpack:nl',
    numberFormat: {
      code: 'nl',
      currency: 'EUR',
    },
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0